exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-s-en-js": () => import("./../../../src/pages/s/en.js" /* webpackChunkName: "component---src-pages-s-en-js" */),
  "component---src-pages-s-zh-js": () => import("./../../../src/pages/s/zh.js" /* webpackChunkName: "component---src-pages-s-zh-js" */),
  "component---src-pages-strategies-en-js": () => import("./../../../src/pages/strategies/en.js" /* webpackChunkName: "component---src-pages-strategies-en-js" */),
  "component---src-pages-test-index-js": () => import("./../../../src/pages/test/index.js" /* webpackChunkName: "component---src-pages-test-index-js" */),
  "component---src-pages-test-test-1-js": () => import("./../../../src/pages/test/test-1.js" /* webpackChunkName: "component---src-pages-test-test-1-js" */),
  "component---src-pages-test-zh-js": () => import("./../../../src/pages/test/zh.js" /* webpackChunkName: "component---src-pages-test-zh-js" */)
}

